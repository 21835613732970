<template>
	<div>
		<div ref="scrollDiv" class="about-mobile" v-if="isMobile">
			<div class="banner posRelative">
				<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
				<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
				<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
					:muted="isMuted" v-if="banner.type===2"></video>
				<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
					<span>{{banner.title}}</span>
					<span class="margin-top40">{{banner.sub_title}}</span>
				</div>
				<div v-if="banner.type===2">
					<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
						@click.stop="isMuted=false">
					<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else
						@click.stop="isMuted=true">
				</div>
			</div>

			<el-affix :offset="tabOffset">
				<div class="tab-div row-me row-center flex-wrap">
					<div class="tab-item column-me row-center" v-for="(item,index) in tabArray" :key="index"
						@click="clickTab(index)">
						<div class="tab-title" :class="{'tab-title-active':tabIndex===index}">{{item.name}}</div>
						<div class="tab-line" :class="{'tab-line-active':tabIndex===index}"></div>
					</div>
				</div>
			</el-affix>

			<div>

				<div class="part1">
					<div id="scrollDiv0" ref="scrollDiv0"></div>
					<div :class="{'bottom-to-top-anim':showPart1Anim}">
						<div class="title-text">{{detailItem.company_name}}</div>
						<div class="title-line"></div>
						<div class="introduce" v-html="detailItem.company_introduce"></div>
					</div>

					<div id="scrollDiv1" ref="scrollDiv1"></div>
					<div :class="{'bottom-to-top-anim':showPart2Anim}">
						<div class="title-text margin-top100" v-if="language==='0'">团队介绍</div>
						<div class="title-text margin-top100" v-if="language==='1'">Team Introduction</div>
						<div class="title-line"></div>
						<div class="column-me posRelative margin-top60">
							<img src="../assets/img/btn_shang.png" class="img-icon1 anim-bigger-small margin-bottom20"
								@click="clickLeft">
							<div class="row-me center-all flex-wrap flex1">
								<div class="item-person column-me" v-for="(item,index) in personArray" :key="index"
									v-show="index>=0&&index<4" @click="clickPerson(item,index)">
									<img :src="item.showSecond?item.change_img:item.img"
										class="img-person anim-bigger-small" draggable="false"
										@mouseover="item.showSecond=true" @mouseout="item.showSecond=false">
									<div class="title1 margin-top10">{{item.name}}</div>
									<div class="title2 margin-top15">{{item.tags.join('、')}}</div>
								</div>
							</div>
							<img src="../assets/img/btn_xia.png" class="img-icon2 anim-bigger-small"
								@click="clickRight">
						</div>
					</div>
				</div>

				<div class="part2 posRelative" ref="scrollDiv2">
					<img src="../assets/img/bg_fzls.png" class="posAbsoluteAll">
					<div id="scrollDiv2"></div>
					<div class="posRelative" :class="{'bottom-to-top-anim':showPart3Anim}">
						<div class="main-content posRelative">
							<div class="title-text-white" v-if="language==='0'">发展历史</div>
							<div class="title-text-white" v-if="language==='1'">Development history</div>
							<div class="title-line"></div>
							<div class="card-div margin-top60">
								<div class="title" v-html="detailItem.history"></div>
							</div>
						</div>
					</div>
				</div>

				<div id="scrollDiv3" ref="scrollDiv3"></div>
				<div class="part3" :class="{'bottom-to-top-anim':showPart4Anim}">
					<div class="title-text" v-if="language==='0'">资源与优势</div>
					<div class="title-text" v-if="language==='1'">Resources and advantages</div>
					<div class="title-line"></div>
					<div class="margin-top60 title" v-html="detailItem.advantage"></div>
				</div>

				<div id="scrollDiv4" ref="scrollDiv4"></div>
				<div class="part4" :class="{'bottom-to-top-anim':showPart5Anim}">
					<div class="title-text" v-if="language==='0'">投资者关系</div>
					<div class="title-text" v-if="language==='1'">Investor relations</div>
					<div class="title-line"></div>
					<div class="margin-top60 row-me flex-wrap">
						<div class="item-card column-me row-center"
							v-for="(item,index) in detailItem.investor_relations" :key="index" @click="clickItem(item)">
							<div class="card-div row-me center-all">
								<img :src="item.cover" class="img">
							</div>
							<div class="title">{{item.name}}</div>
						</div>
					</div>
				</div>

			</div>

			<!-- 团队详情 -->
			<el-drawer v-model="showTeamDetail" :with-header="false" size="80%" :z-index="9999">
				<div class="team-detail column-me">
					<div class="row-me row-center flex-end1">
						<img src="../assets/img/close.png" class="img-close anim-bigger-small"
							@click="showTeamDetail=false">
					</div>
					<transition name="el-fade-in-linear">
						<div class="flex1 column-me row-center" v-if="selectPerson">
							<img :src="selectPerson.img" class="img-person anim-bigger-small" draggable="false">
							<div class="column-me column-center margin-top20">
								<div class="title1">{{selectPerson.name}}</div>
								<div class="title2 margin-top30">{{selectPerson.tags.join('&nbsp;&nbsp;')}}</div>
								<div class="title3 margin-top30" v-html="selectPerson.introduce"></div>
								<div class="row-me row-center margin-top100">
									<img src="../assets/img/left.png" class="img-left anim-bigger-small"
										@click="clickChangeLeft">
									<div class="line"></div>
									<img src="../assets/img/right.png" class="img-left anim-bigger-small"
										@click="clickChangeRight">
								</div>
							</div>
						</div>
					</transition>
				</div>
			</el-drawer>
		</div>


		<div ref="scrollDiv" class="about" v-else>
			<div class="banner posRelative">
				<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
				<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
				<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
					:muted="isMuted" v-if="banner.type===2"></video>
				<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
					<span>{{banner.title}}</span>
					<span class="margin-top40">{{banner.sub_title}}</span>
				</div>
				<div v-if="banner.type===2">
					<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
						@click.stop="isMuted=false">
					<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else
						@click.stop="isMuted=true">
				</div>
			</div>

			<el-affix :offset="tabOffset">
				<div class="tab-div row-me row-center flex-end2">
					<div class="tab-item column-me row-center" v-for="(item,index) in tabArray" :key="index"
						@click="clickTab(index)">
						<div class="tab-title" :class="{'tab-title-active':tabIndex===index}">{{item.name}}</div>
						<div class="tab-line" :class="{'tab-line-active':tabIndex===index}"></div>
					</div>
				</div>
			</el-affix>

			<div>

				<div class="part1">
					<div id="scrollDiv0" ref="scrollDiv0"></div>
					<div ref="part1" :class="{'bottom-to-top-anim':showPart1Anim}">
						<div class="title-text">{{detailItem.company_name}}</div>
						<div class="title-line"></div>
						<div class="introduce" v-html="detailItem.company_introduce"></div>
					</div>

					<div id="scrollDiv1" ref="scrollDiv1"></div>
					<div ref="part2" :class="{'bottom-to-top-anim':showPart2Anim}">
						<div class="title-text margin-top100" v-if="language==='0'">团队介绍</div>
						<div class="title-text margin-top100" v-if="language==='1'">Team Introduction</div>
						<div class="title-line"></div>
						<div class="row-me row-center posRelative margin-top60">
							<img src="../assets/img/btn_shang.png" class="img-icon1 anim-bigger-small"
								@click="clickLeft">
							<div class="row-me center-all flex1">
								<div class="item-person column-me" v-for="(item,index) in personArray" :key="index"
									v-show="index>=0&&index<4" @click="clickPerson(item,index)">
									<img :src="item.showSecond?item.change_img:item.img" class="img-person"
										draggable="false" @mouseover="item.showSecond=true"
										@mouseout="item.showSecond=false">
									<div class="title1 margin-top30">{{item.name}}</div>
									<div class="title2 margin-top15">{{item.tags.join('、')}}</div>
								</div>
							</div>
							<img src="../assets/img/btn_xia.png" class="img-icon2 anim-bigger-small"
								@click="clickRight">
						</div>
					</div>
				</div>

				<div class="part2 posRelative" ref="scrollDiv2">
					<img src="../assets/img/bg_fzls.png" class="posAbsoluteAll">
					<div id="scrollDiv2"></div>
					<div ref="part3" class="posAbsoluteAll" :class="{'bottom-to-top-anim':showPart3Anim}">
						<div class="main-content">
							<div class="title-text-white" v-if="language==='0'">发展历史</div>
							<div class="title-text-white" v-if="language==='1'">Development history</div>
							<div class="title-line"></div>
							<div class="card-div margin-top60">
								<div class="title" v-html="detailItem.history"></div>
							</div>
						</div>
					</div>
				</div>

				<div id="scrollDiv3" ref="scrollDiv3"></div>
				<div ref="part4" class="part3" :class="{'bottom-to-top-anim':showPart4Anim}">
					<div class="title-text" v-if="language==='0'">资源与优势</div>
					<div class="title-text" v-if="language==='1'">Resources and advantages</div>
					<div class="title-line"></div>
					<div class="margin-top60 title" v-html="detailItem.advantage"></div>
				</div>

				<div id="scrollDiv4" ref="scrollDiv4"></div>
				<div ref="part5" class="part4" :class="{'bottom-to-top-anim':showPart5Anim}">
					<div class="title-text" v-if="language==='0'">投资者关系</div>
					<div class="title-text" v-if="language==='1'">Investor relations</div>
					<div class="title-line"></div>
					<div class="margin-top60 row-me flex-wrap">
						<div class="item-card column-me row-center"
							v-for="(item,index) in detailItem.investor_relations" :key="index" @click="clickItem(item)">
							<div class="card-div row-me center-all">
								<img :src="item.cover" class="img anim-bigger-small">
							</div>
							<div class="title">{{item.name}}</div>
						</div>
					</div>
				</div>

			</div>

			<!-- 团队详情 -->
			<el-drawer v-model="showTeamDetail" :with-header="false" size="80%" :z-index="9999">
				<div class="team-detail column-me">
					<div class="row-me row-center flex-end1">
						<img src="../assets/img/close.png" class="img-close anim-bigger-small"
							@click="showTeamDetail=false">
					</div>
					<transition name="el-fade-in-linear">
						<div class="flex1 row-me row-center" v-if="selectPerson">
							<img :src="selectPerson.img" class="img-person" draggable="false">
							<div class="column-me column-center margin-left120 margin-right120">
								<div class="title1">{{selectPerson.name}}</div>
								<div class="title2 margin-top30">{{selectPerson.tags.join('&nbsp;&nbsp;')}}</div>
								<div class="title3 margin-top50" v-html="selectPerson.introduce"></div>
								<div class="row-me row-center margin-top100">
									<img src="../assets/img/left.png" class="img-left anim-bigger-small"
										@click="clickChangeLeft">
									<div class="line"></div>
									<img src="../assets/img/right.png" class="img-left anim-bigger-small"
										@click="clickChangeRight">
								</div>
							</div>
						</div>
					</transition>
				</div>
			</el-drawer>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				banner: {},
				showBannerAnim: false,
				tabIndex: 0,
				tabArray: [],
				personArray: [],
				showPart1Anim: false,
				showPart2Anim: false,
				showPart3Anim: false,
				showPart4Anim: false,
				showPart5Anim: false,
				selectPerson: null, //点击的人员
				selectIndex: -1,
				showTeamDetail: false, //显示团队详情
				detailItem: {},
				language: '', //0中文，1英文
				isMobile: '', //0电脑，1手机
				isMuted: true, //视频是否静音
				tabOffset: 90,
			}
		},
		watch: {
			scrollTop: {
				handler: function(newVal, oldVal) {
					if (!this.$method.isElementNotInViewport(this.$refs['part1']) && !this.isMobile) {
						this.showPart1Anim = true
					}
					if (!this.$method.isElementNotInViewport(this.$refs['part2']) && !this.isMobile) {
						this.showPart2Anim = true
					}
					if (!this.$method.isElementNotInViewport(this.$refs['part3']) && !this.isMobile) {
						this.showPart3Anim = true
					}
					if (!this.$method.isElementNotInViewport(this.$refs['part4']) && !this.isMobile) {
						this.showPart4Anim = true
					}
					if (!this.$method.isElementNotInViewport(this.$refs['part5']) && !this.isMobile) {
						this.showPart5Anim = true
					}
					if (this.showPart1Anim) {
						this.tabIndex = 0
					}
					if (this.showPart2Anim) {
						this.tabIndex = 1
					}
					if (this.showPart3Anim) {
						this.tabIndex = 2
					}
					if (this.showPart4Anim) {
						this.tabIndex = 3
					}
					if (this.showPart5Anim) {
						this.tabIndex = 4
					}
				}
			},
			navItem: {
				handler: function(newVal, oldVal) {
					this.getBanner()
					this.getData()
				}
			}
		},
		computed: {
			scrollTop() {
				return this.$store.state.scrollTop;
			},
			navItem() {
				return this.$store.state.navItem;
			},
		},
		created() {
			this.isMobile = sessionStorage.getItem("mobile") === '1'
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			if (this.language === '0') {
				this.tabArray = [{
					id: 0,
					name: '公司简介'
				}, {
					id: 1,
					name: '团队介绍'
				}, {
					id: 2,
					name: '发展历史'
				}, {
					id: 3,
					name: '资源与优势'
				}, {
					id: 4,
					name: '投资者关系'
				}]
			} else if (this.language === '1') {
				this.tabArray = [{
					id: 0,
					name: 'Company profile'
				}, {
					id: 1,
					name: 'Team Introduction'
				}, {
					id: 2,
					name: 'Development history'
				}, {
					id: 3,
					name: 'Resources and advantages'
				}, {
					id: 4,
					name: 'Investor relations'
				}]
			}

			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
			this.getBanner()
			this.getData()
		},
		activated() {
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			this.tabIndex = 0
			this.showBannerAnim = false
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
			this.showPart1Anim = false
			this.showPart2Anim = false
			this.showPart3Anim = false
			this.showPart4Anim = false
			this.showPart5Anim = false
		},
		mounted() {
			this.$nextTick(() => {
				let headerDiv = document.getElementById('headerDiv')
				this.tabOffset = headerDiv.clientHeight
			})
		},
		methods: {
			clickTab(index) {
				this.tabIndex = index
				let top = this.$refs['scrollDiv' + index].getBoundingClientRect().top
				if (this.isMobile) {
					top -= 350
				} else {
					top += 50
				}
				this.$bus.$emit('scrollPage', {
					tabIndex: index,
					top: top,
				})
			},
			clickLeft() {
				let item = this.personArray.pop()
				this.personArray.unshift(item)
			},
			clickRight() {
				let spliceArray = this.personArray.splice(0, 1)
				this.personArray = this.personArray.concat(spliceArray)
			},
			clickPerson(item, index) {
				this.selectPerson = item
				this.selectIndex = index
				this.showTeamDetail = true
			},
			clickChangeLeft() {
				this.selectPerson = null
				setTimeout(() => {
					if (this.selectIndex === 0) {
						let item = this.personArray.pop()
						this.personArray.unshift(item)
						this.selectPerson = item
					} else {
						this.selectIndex--
						this.selectPerson = this.personArray[this.selectIndex]
					}
				}, 300)
			},
			clickChangeRight() {
				this.selectPerson = null
				setTimeout(() => {
					if (this.selectIndex === this.personArray.length - 1) {
						let spliceArray = this.personArray.splice(0, 1)
						this.personArray = this.personArray.concat(spliceArray)
						this.selectPerson = this.personArray[this.personArray.length - 1]
					} else {
						this.selectIndex++
						this.selectPerson = this.personArray[this.selectIndex]
					}
				}, 300)
			},
			//投资者关系点击
			clickItem(item) {
				this.$router.push({
					path: '/partnerDetail',
					query: {
						id: item.id
					}
				})
			},
			getBanner() {
				this.banner = {}
				let link_url = this.$store.state.navItem.link_url
				if (link_url == '/about') {
					this.$postRequest('/web/navInfo', {
						id: this.$store.state.navItem.id
					}).then((res) => {
						if (res) {
							this.banner = res.data
						}
					}).catch((error) => {
						console.log(error)
					})
				}
			},
			//获取数据
			getData() {
				this.$postRequest('/web/aboutUsContent', {}).then((res) => {
					if (res) {
						this.personArray = res.data.team
						this.detailItem = res.data
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.about-mobile {
		height: 100%;

		.banner {
			width: 100%;
			height: 35vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 25px;
				color: #FFFFFF;
				position: absolute;
				left: 20px;
				top: 30%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.tab-div {
			padding: 10px;
			border-bottom: 1px solid #ECECEC;
			background-color: #FFFFFF;

			.tab-item {
				margin-right: 20px;
				margin-bottom: 10px;
				cursor: pointer;

				&:hover {
					.tab-title {
						color: #1D9E9B;
					}
				}

				.tab-title {
					font-size: 18px;
					color: #0B0F0F;

					&-active {
						color: #1D9E9B;
					}
				}

				.tab-line {
					width: 72px;
					height: 4px;
					background: #FFFFFF;
					margin-top: 30px;

					&-active {
						background: #1D9E9B;
					}
				}
			}
		}

		.part1 {
			padding: 20px;

			.introduce {
				font-size: 18px;
				color: #000000;
				margin-top: 30px;
			}

			.img-icon1 {
				width: 68px;
				height: 68px;
				cursor: pointer;
			}

			.img-icon2 {
				width: 68px;
				height: 68px;
				cursor: pointer;
			}

			.item-person {
				cursor: pointer;
				margin-right: 30px;
				margin-bottom: 30px;

				&:nth-child(4n) {
					margin-right: 0;
				}

				.img-person {
					width: 100%;
					height: 350px;
					object-fit: cover;
				}

				.title1 {
					width: 302px;
					font-size: 24px;
				}

				.title2 {
					width: 302px;
					font-size: 18px;
					color: #999999;
				}
			}
		}

		.part2 {

			.main-content {
				padding: 20px;

				.card-div {
					background: #FFFFFF;
					padding: 20px;

					.title {
						font-size: 22px;
						color: #0B0F0F;
						overflow: auto;
					}
				}
			}
		}

		.part3 {
			padding: 20px;
			background: #FFFFFF;

			.title {
				font-size: 22px;
				color: #0B0F0F;
			}
		}

		.part4 {
			padding: 20px;
			background: #F5F5F7;

			.item-card {
				width: 45%;
				margin-right: 20px;
				margin-bottom: 30px;
				cursor: pointer;

				&:nth-child(2n) {
					margin-right: 0;
				}
			}

			.title {
				width: 90%;
				text-align: center;
				font-size: 20px;
				color: #0B0F0F;
				margin-top: 10px;
			}

			.card-div {
				width: 100%;
				height: 100px;
				background: #FFFFFF;

				.img {
					width: 100%;
					height: 100%;
					object-fit: scale-down;
				}
			}
		}

		.team-detail {
			height: 100%;

			.img-close {
				width: 30px;
				height: 30px;
				margin: 10px;
				object-fit: scale-down;
				cursor: pointer;
			}

			.img-person {
				width: 100%;
				object-fit: cover;
				margin-left: 10px;
			}

			.title1 {
				font-size: 30px;
				color: #0B0F0F;
			}

			.title2 {
				font-size: 20px;
				color: #1D9E9B;
			}

			.title3 {
				font-size: 20px;
				color: #0B0F0F;
				overflow: auto;
			}

			.line {
				width: 1px;
				height: 40px;
				background-color: #DDDDDD;
				margin: 0 20px;
			}

			.img-left {
				width: 20px;
				height: 30px;
				object-fit: scale-down;
				padding: 10px;
				cursor: pointer;
			}
		}

	}

	.about {
		height: 100%;

		.banner {
			width: 100%;
			height: 80vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 80px;
				color: #FFFFFF;
				position: absolute;
				left: 278px;
				top: 40%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.tab-div {
			padding: 0 278px;
			height: 90px;
			border-bottom: 1px solid #ECECEC;
			background-color: #FFFFFF;

			.tab-item {
				margin-right: 80px;
				cursor: pointer;

				&:hover {
					.tab-title {
						color: #1D9E9B;
					}
				}

				.tab-title {
					font-size: 18px;
					color: #0B0F0F;

					&-active {
						color: #1D9E9B;
					}
				}

				.tab-line {
					width: 72px;
					height: 4px;
					background: #FFFFFF;
					margin-top: 30px;

					&-active {
						background: #1D9E9B;
					}
				}
			}
		}

		.part1 {
			padding: 100px 278px 180px 278px;

			.introduce {
				font-size: 22px;
				color: #000000;
				margin-top: 60px;
				line-height: 40px;
			}

			.img-icon1 {
				width: 68px;
				height: 68px;
				position: absolute;
				left: -120px;
				cursor: pointer;
			}

			.img-icon2 {
				width: 68px;
				height: 68px;
				position: absolute;
				right: -120px;
				cursor: pointer;
			}

			.item-person {
				cursor: pointer;
				margin-right: 52px;

				&:nth-child(4n) {
					margin-right: 0;
				}

				.img-person {
					width: 302px;
					height: 404px;
					object-fit: scale-down;
				}

				.title1 {
					width: 302px;
					font-size: 24px;
				}

				.title2 {
					width: 302px;
					font-size: 18px;
					color: #999999;
				}
			}
		}

		.part2 {
			height: 1080px;

			.main-content {
				padding: 100px 278px;

				.card-div {
					width: 1244px;
					background: #FFFFFF;
					padding: 80px 60px;

					.title {
						height: 520px;
						font-size: 22px;
						color: #0B0F0F;
						line-height: 40px;
						overflow: auto;
					}
				}
			}
		}

		.part3 {
			padding: 150px 278px;
			background: #FFFFFF;

			.title {
				font-size: 22px;
				color: #0B0F0F;
				line-height: 40px;
			}
		}

		.part4 {
			padding: 150px 278px;
			background: #F5F5F7;

			.item-card {
				margin-right: 52px;
				margin-bottom: 88px;
				cursor: pointer;

				&:nth-child(3n) {
					margin-right: 0;
				}
			}

			.title {
				width: 420px;
				text-align: center;
				font-size: 22px;
				color: #0B0F0F;
				margin-top: 40px;
			}

			.card-div {
				width: 420px;
				height: 240px;
				background: #FFFFFF;

				.img {
					width: 300px;
					height: 200px;
					object-fit: scale-down;
				}
			}
		}

		.team-detail {
			height: 100%;

			.img-close {
				width: 30px;
				height: 30px;
				margin: 10px;
				object-fit: scale-down;
				cursor: pointer;
			}

			.img-person {
				width: 450px;
				height: 602px;
				object-fit: scale-down;
				margin-left: 120px;
			}

			.title1 {
				font-size: 50px;
				color: #0B0F0F;
			}

			.title2 {
				font-size: 24px;
				color: #1D9E9B;
			}

			.title3 {
				height: 300px;
				font-size: 20px;
				color: #0B0F0F;
				line-height: 40px;
				overflow: auto;
			}

			.line {
				width: 1px;
				height: 40px;
				background-color: #DDDDDD;
				margin: 0 20px;
			}

			.img-left {
				width: 20px;
				height: 30px;
				object-fit: scale-down;
				padding: 10px;
				cursor: pointer;
			}
		}

	}
</style>
